import React, { useEffect } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import ImgFish from "./ImgFish"

import * as styles from "./banner.module.scss"
import { useObserver } from "../../../hooks/useObserver"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

type BannerProps = {
  buttonText?: string
  title?: string
  titleMob?: string
  description?: string
  orderNum?: string
  isPkw?: boolean
  variantStyle?: string
}

const defaultTitleMob = `
Рассрочка до 24 мес. </br>
по карте «Халва»
`

const defaultTitle = `
Рассрочка до 24 месяцев </br>
по карте «Халва»
`

const defaultDescription = `
— До 3% к ставке вклада
<br />
— Кэшбэк до 10%
<br />
— Обслуживание 0 ₽ 
`

export function Banner({
  buttonText = "Оформить карту",
  title = defaultTitle,
  titleMob = defaultTitleMob,
  description = defaultDescription,
  orderNum,
  isPkw = false,
  variantStyle,
}: BannerProps) {
  useEffect(() => {
    document.body.style.display = "block"
  }, [])
  const isMob = useMediaQuery("(max-width:599.5px)")
  const isVisible = useObserver(["app", "calculatorCard", "reviewCard", "moreBenefits", "formPkw"])

  const finalTitle = isMob ? titleMob : title

  return (
    <>
      <section className={variantStyle && styles[variantStyle]} data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={clsx(styles.secondContainer)}>
            {isPkw ? (
              <ImgFish alt="bg image" className={styles.img} />
            ) : (
              <Img alt="bg image" className={styles.img} />
            )}

            <div className={styles.text}>
              <h1 dangerouslySetInnerHTML={{ __html: finalTitle }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
